<template>
  <div>
    <div class="custom-search">
      <!-- Fecha Inicio -->
      <b-row>
        <b-col cols="2">
          <br>
          <b-modal-ingreso-ticket />
        </b-col>
        <b-col cols="2">
          <b-form-group>
            <label>Fecha Inicio:</label>
            <b-input-group class="mb-1">
              <b-form-input
                id="example-input"
                v-model="fechaInicio"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                show-decade-nav
              />
              <b-input-group-append>
                <b-form-datepicker
                  v-model="fechaInicio"
                  show-decade-nav
                  button-only
                  locale="en-US"
                  aria-controls="example-input"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- Fecha Fin -->
        <b-col cols="2">
          <b-form-group>
            <label>Fecha Fin:</label>
            <b-input-group class="mb-1">
              <b-form-input
                id="fecha-fin"
                v-model="fechaFin"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                show-decade-nav
              />
              <b-input-group-append>
                <b-form-datepicker
                  v-model="fechaFin"
                  show-decade-nav
                  button-only
                  locale="en-US"
                  aria-controls="example-input"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- Boton Busqueda -->
        <b-col cols="1">
          <br>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
            style="margin-top: 7px;"
            @click="loadReport()"
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-col>
        <br>
        <!-- Autorizar -->
        <b-col cols="3">
          <table>
            <tr>
              <td style="width: 50%">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="btn btn-primary float-right"
                  style="margin-top: 22px"
                  @click="autorizar"
                >
                  Autorizar
                </b-button>
              </td>
              <td style="width: 50%">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="btn btn-danger float-right"
                  style="margin-top: 22px"
                  @click="rechazar"
                >
                  Rechazar
                </b-button>
              </td>
            </tr>
          </table>

        </b-col>
        <br>
        <!-- disabled input -->
        <b-col
          cols="2"
        >
          <label>Total</label>
          <b-form-input
            id="disabledInput"
            v-model="montoTotal"
            disabled
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <!-- table -->
          <vue-good-table
            ref="my-table"
            :columns="columns"
            :rows="rows"
            :pagination-options="{
              enabled: true,
              perPage: pageLength
            }"
            :search-options="{
              enabled: true,
              placeholder: 'Buscar...',
            }"
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            style-class="vgt-table condensed"
            @on-row-dblclick="onRowClick"
            @on-select-all="onSelectAll"
            @on-selected-rows-change="selectionChanged"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: Poste -->
              <div
                v-if="props.column.field === 'poste'"
                style="font-size: 13px;"
              >
                <span>
                  &nbsp;&nbsp;<b>{{ props.formattedRow[props.column.field] }}</b>
                </span>
              </div>
              <!-- Column: Common -->
              <div
                v-else
                style="font-size: 12px;"
              >
                <span>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </div>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Mostrar
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10', '15', '20']"
                    class="mx-1"
                    @input="value => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap"> de {{ props.total }} registros </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="value => props.pageChanged({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </div>
    <!---- MODALES ---->
    <!--<form-ampliaciones />-->

    <b-modal
      id="modal-lg"
      ref="my-modal"
      v-model="dialog"
      hide-footer
      centered
      size="lg"
      modal-class="modal-primary"
      title="Formulario Ampliaciones"
      no-close-on-backdrop
      scrollable
    >
      <detalle-ampliacion
        :ampliacion="ampliacion"
      />
    </b-modal>
    <!------ MODAL AUTORIZAR
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal-2"
      v-model="dialog2"
      :title="tipoOperacion === 'RECHAZADA' ? 'Rechazar Ampliaciones' : 'Autorizar Ampliaciones'"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      ok-title="Guardar"
      cancel-title="Cancelar"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          v-if="tipoOperacion === 'AUTORIZADO'"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <h5>Asignar Encargado</h5>
          <v-select
            v-model="usuarioAsignar"
            :state="usuarioState"
            :options="usuarios"
            label="nombre"
          />
        </b-form-group>
        <b-form-group
          v-if="tipoOperacion === 'RECHAZADA'"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <h5>Ingresar Comentarios</h5>
          <b-form-textarea
            v-model="comentariosAnular"
            :state="comentarioState"
          />
        </b-form-group>
      </form>
    </b-modal ---->
  </div>
</template>

<script>

import DetalleAmpliacion from '@/components/Inspecciones/ampliaciones/DetalleAmpliacion.vue'
import {
  BButton, BCol, BRow, BFormDatepicker, BInputGroup, BFormGroup, BInputGroupAppend, BFormInput, BFormSelect, BPagination, VBPopover, VBModal, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import { findAmplicacionId, findAmpliaciones, updateCreateAmpliacion } from '@/utils/inspecciones'
import { formatDate, obtenerFechaInicioFin, sumarDiasFecha } from '@/utils/fechas'
import 'vue-good-table/dist/vue-good-table.css'
import { generatePDF } from '@/utils/files'
import BModalIngresoTicket from '@/components/Tickets/BModalIngresoTicket.vue'
import { getUsersByCompany } from '@/utils/usuarios'

export default {
  components: {
    BButton,
    BCol,
    BFormTextarea,
    BRow,
    BFormDatepicker,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    BFormInput,
    VueGoodTable,
    BFormSelect,
    BPagination,
    DetalleAmpliacion,
    BModalIngresoTicket,
    vSelect,
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
    'b-modal': VBModal,
  },
  data() {
    return {
      usuario: JSON.parse(localStorage.getItem('userData')),
      usuarios: [],
      fechaInicio: '',
      comentariosAnular: '',
      usuarioAsignar: null,
      usuarioState: null,
      comentarioState: null,
      localization: null,
      fechaFin: '',
      rows: [],
      columns: [
        {
          label: 'Poste',
          field: 'poste',
        },
        {
          label: 'Creación',
          field: 'fechaCreacion',
        },
        {
          label: 'Localidad',
          field: 'localidad.nombre',
        },
        {
          label: 'Zona',
          field: 'localidad.zona',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Precio Total',
          field: 'precioTotal',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },

      ],
      searchTerm: '',
      perPage: 5,
      totalRows: 1,
      currentPage: 1,
      pageLength: 15,
      dialog: false,
      dialog2: false,
      tipoOperacion: '',
      ampliacion: null,
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      seleccionados: [],
      montoTotal: 'Q 0.00',
    }
  },
  async created() {
    this.seleccionados = []
    this.rows = []

    this.usuarios = await getUsersByCompany(this.usuario.idCompany)
    this.usuarios.map(usuario => {
      // eslint-disable-next-line no-param-reassign
      usuario.nombre = `${usuario.firstName} ${usuario.firstSurname}`
      return usuario
    })

    const fechas = obtenerFechaInicioFin()
    this.fechaFin = fechas.fechaFin
    this.fechaInicio = fechas.fechaInicio
    await this.loadReport()

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (
          this.localization = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          }),
        error => {
          console.error('Error Obteniendo Latitud y Longitud!', error)
          this.localization = null
        },
      )
    }
  },
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    async handleSubmit() {
      try {
        // eslint-disable-next-line no-restricted-syntax
        for await (const item of this.seleccionados) {
          item.estado = this.tipoOperacion

          if (this.tipoOperacion === 'AUTORIZADO') {
            item.tracking.push({
              localization: this.localization,
              tipo: 'Autorización de Ampliación',
              comentarios: '',
              fechaHora: new Date(),
              usuario: {
                id: this.usuario.id,
                nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
              },
            })
          } else {
            item.tracking.push({
              localization: this.localization,
              tipo: 'Rechazo de Ampliación',
              comentarios: this.comentariosAnular,
              fechaHora: new Date(),
              usuario: {
                id: this.usuario.id,
                nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
              },
            })
          }

          delete item.originalIndex
          delete item.vgtSelected
          delete item.vgt_id
          delete item.grupo
          delete item.fechaInstalacion
          delete item.fechaCreacion
          await updateCreateAmpliacion(item, 1)
        }
        await this.loadReport()
        this.seleccionados = []

        if (this.tipoOperacion === 'AUTORIZADO') this.success('Autorización Finalizada', 'Se autorizaron correctamente las ampliaciones seleccionadas!')
        else this.success('Ampliación Rechazada', 'Se rechazaron correctamente las ampliaciones seleccionadas!')
      } catch (err) {
        console.error(err)
      }
    },
    resetModal() {
      this.usuarioAsignar = null
      this.comentariosAnular = ''
      this.usuarioState = null
      this.comentarioState = null
    },
    async autorizar() {
      if (this.seleccionados.length === 0) return
      this.$bvModal
        .msgBoxConfirm(`Está seguro de autorizar ${this.seleccionados.length} ampliciaciones por un total de ${this.montoTotal}`, {
          title: 'Confirmación de Autorizaciones',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Si',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          try {
            if (value) {
              // eslint-disable-next-line no-restricted-syntax
              this.tipoOperacion = 'AUTORIZADO'
              await this.handleSubmit()
            }
          } catch (err) {
            console.error('error autorizando ', err)
            this.error('Ocurrió un incoveniente autorizando las ampliaciones, intente nuevamente!')
          }
        })
    },
    async rechazar() {
      if (this.seleccionados.length === 0) return
      this.$bvModal
        .msgBoxConfirm(`Está seguro de rechazar ${this.seleccionados.length} ampliciaciones`, {
          title: 'Confirmación de Rechazo',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Si',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          try {
            if (value) {
              // eslint-disable-next-line no-restricted-syntax
              this.tipoOperacion = 'RECHAZADA'
              this.dialog2 = true
            }
          } catch (err) {
            console.error('error rechazando ', err)
            this.error('Ocurrió un incoveniente rechazando las ampliaciones, intente nuevamente!')
          }
        })
    },
    onSelectAll(params) {
      // params.selected - whether the select-all checkbox is checked or unchecked
      // params.selectedRows - all rows that are selected (this page)
      this.seleccionados = params.selectedRows
      this.recalcularTotal()
    },
    success(titulo, mensaje) {
      this.$swal({
        title: titulo,
        text: mensaje,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    error(mensaje) {
      this.$swal({
        title: 'Error!',
        text: mensaje,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    selectionChanged(params) {
      // params.selectedRows - all rows that are selected (this page)
      this.seleccionados = params.selectedRows
      this.recalcularTotal()
    },
    recalcularTotal() {
      let total = 0
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const item of this.seleccionados) {
        // eslint-disable-next-line no-restricted-syntax
        for (const row of item.items) {
          let valor = row.item.precio.precioVenta
          if (row.item.nombre.toString().toLowerCase().includes('metro')) valor = row.item.precio.precioVenta * item.metros

          total += Math.round(valor)
        }
      }
      this.montoTotal = `Q. ${total}.00`
    },
    async generarDocumento() {
      try {
        const result = await generatePDF()
        if (result !== null) {
          const linkSource = `data:application/pdf;base64,${result.Base64}`
          const downloadLink = document.createElement('a')
          const fileName = 'pruebas.pdf'
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        }
      } catch (error) {
        console.error(error)
      }
    },
    async onRowClick(params) {
      if (typeof params === 'object') this.ampliacion = await findAmplicacionId(params.row.id)
      else this.ampliacion = await findAmplicacionId(params)
      this.dialog = true
    },
    async loadReport() {
      this.dialog = false
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].hide()
      })

      const fechaInicio = new Date(this.fechaInicio)
      const fechaFin = sumarDiasFecha(new Date(this.fechaFin), 2)

      const filter = {
        where: {
          and: [
            { fechaCreacion: { gte: fechaInicio.toISOString() } },
            { fechaCreacion: { lte: fechaFin.toISOString() } },
            { estado: 'AUTORIZACION' },
            { companyId: this.usuario.idCompany },
          ],
        },
        order: ['fechaCreacion DESC'],
      }

      const data = await findAmpliaciones(filter)
      // eslint-disable-next-line no-restricted-syntax
      for await (const item of data) {
        item.fechaAtencion = item.tracking.length > 0 ? formatDate(item.tracking[0].fechaHora, '/') : ''
        item.fechaCreacion = formatDate(item.fechaCreacion, '/')
        item.precioTotal = 0
        // eslint-disable-next-line no-restricted-syntax
        for (const row of item.items) {
          let valor = row.item.precio.precioVenta
          if (row.item.nombre.toString().toLowerCase().includes('metro')) valor = row.item.precio.precioVenta * item.metros

          if (row.item !== null) item.precioTotal += Math.round(valor)
        }
        item.precioTotal = `Q. ${item.precioTotal}.00`
      }
      this.rows = data
    },
  },
}

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
